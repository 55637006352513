import * as THREE from 'three';

export const smoothstep = (x, range) => {

  if (range[0] > range[1]) {
    if (x > range[0]) return 0;
    if (x < range[1]) return 1;
    return THREE.MathUtils.smoothstep(x, range[0], range[1]);
  }

  if (x < range[0]) return 0;
  if (x > range[1]) return 1;
  return THREE.MathUtils.smoothstep(x, range[0], range[1]);

};

export const almostUnitIdentity = (x, range) => {
  if (range[0] > range[1]) {
    if (x > range[0]) return 0;
    if (x < range[1]) return 1;
    const xa = (x - range[1]) / (range[0] - range[1]);
    return xa * xa * (2.0 - xa);
  }
  if (x < range[0]) return 0;
  if (x > range[1]) return 1;
  const xa = (x - range[0]) / (range[1] - range[0]);
  return xa * xa * (2.0 - xa);
};

export const delay = (timeout, callback) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      callback();
      resolve();
    }, timeout);
  });
};

const rand = (n) => ((Math.sin(n) * 43758.5453123) % 1) * 0.5 + 0.5;

export const noise = (p) => {
  const fl = Math.floor(p);
  const fc = p % 1;
  return THREE.MathUtils.lerp(rand(fl), rand(fl + 1.0), fc);
};

/* eslint-disable no-param-reassign */
import * as THREE from 'three';
import {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import { useFrame, useLoader, useThree } from '@react-three/fiber';

import useStore from '../utils/store';

const setVolume = ({
  sound, frame, frames, volumes,
}) => {
  if (frame < frames[0] || frame > frames[1]) return;
  const x = THREE.MathUtils.smoothstep(frame, frames[0], frames[1]);
  const volume = volumes[0] + (volumes[1] - volumes[0]) * x;
  sound.setVolume(volume);
};

const useSound = () => {

  const mutation = useStore((state) => state.mutation);
  const sounds = useStore((state) => state.sounds);

  useFrame((state, delta) => {
    const { frame } = mutation;

    if (frame === 1) sounds[0].play();
    setVolume({
      sound: sounds[0],
      frame,
      frames: [1, 600],
      volumes: [0, 1],
    });
    setVolume({
      sound: sounds[0],
      frame,
      frames: [2400, 2600],
      volumes: [1, 0],
    });
    if (frame === 2600) sounds[0].pause();

    // Music
    if (frame === 1660) {
      sounds[1].play();
    }
    setVolume({
      sound: sounds[1],
      frame,
      frames: [1660, 2100],
      volumes: [0, 0.5],
    });
    setVolume({
      sound: sounds[1],
      frame,
      frames: [4700, 4790],
      volumes: [0.5, 0],
    });
    if (frame === 4790) {
      sounds[1].pause();
    }

    // Wind
    if (frame === 4700) {
      sounds[2].play();
      sounds[2].setVolume(1.4);
    }
    if (frame === 6300) {
      sounds[2].pause();
    }

  });

};

export default useSound;

import { useFrame } from '@react-three/fiber';
import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
import useStore from '../utils/store';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { noise } from '../utils/helpers';

const Light1 = ({
  position,
  flicker,
  intensity,
  ...rest
}) => {

  const target = useRef();
  const light = useRef();
  const mutation = useStore((state) => state.mutation);

  const targetPosition = [...position];
  targetPosition[1] -= 1;

  useFrame(() => {
    const { frame } = mutation;
    if (flicker) {
      light.current.intensity = intensity * 2
      * Math.min(
        (noise(frame * 0.8) * (Math.abs(Math.sin(frame * 0.01))) + 0.3),
        1,
      );
    }
  });

  return (
    <>
      <spotLight
        angle={0.8}
        target={target.current}
        ref={light}
        penumbra={1}
        castShadow
        position={position}
        intensity={2 * intensity}
        {...rest}
      />
      <points ref={target} position={targetPosition} />
    </>
  );
};

export default Light1;

/* eslint-disable no-mixed-operators */
import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';

const Light0 = ({
  intensity,
}) => {

  const target = useRef();

  return (
    <>
      {/* <spotLight
        position={[-4, 10, 1]}
        angle={Math.PI * 0.035}
        target={target.current}
        penumbra={0.8}
        intensity={intensity * 0.2}
        distance={20}
      />
      <spotLight
        position={[-4, -10, 1]}
        angle={Math.PI / 16}
        target={target.current}
        penumbra={0.8}
        intensity={intensity * 1}
        distance={1000}
      /> */}
      <spotLight
        position={[0, 10, 0]}
        angle={Math.PI / 16}
        target={target.current}
        penumbra={0.8}
        intensity={intensity * 0.4}
        distance={0}
        color={0xfff0ff}
      />
      {/* <spotLight
        position={[0, -5, 5]}
        angle={Math.PI / 16}
        target={target.current}
        penumbra={0.8}
        intensity={intensity * 0.3}
        distance={0}
        color={0xffaaff}
      /> */}
      <spotLight
        position={[4, -15, 5]}
        angle={Math.PI / 16}
        target={target.current}
        penumbra={0.8}
        intensity={intensity * 1.1}
        distance={0}
        color={0xffdfff}
      />

      <points ref={target} position={[0, 0, 0]} />
    </>

  );
};

export default Light0;

/* eslint-disable no-undef */
import React, {
  Suspense, useEffect, useRef, useState,
} from 'react';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';

import {
  useGLTF, PerspectiveCamera,
  useProgress,
  useTexture,
  // DeviceOrientationControls,
} from '@react-three/drei';
import {
  useThree, useFrame, useLoader, extend,
} from '@react-three/fiber';

import Effects from './Effects';

import useStore from '../utils/store';
import useControls from '../hooks/useControls';
import useAnimation from '../hooks/useAnimation';
import * as materials from '../utils/materials';
import useSubtitles from '../hooks/useSubtitles';
import useSound from '../hooks/useSound';
import Stage0 from './Stage0';
import Stage1 from './Stage1';
import Stage2 from './Stage2';
import Stage3 from './Stage3';
import Stage4 from './Stage4';

const quaternion = new THREE.Quaternion();

// const recorder = new CCapture({
//   verbose: false,
//   display: true,
//   framerate: 60,
//   quality: 100,
//   format: 'webm',
//   timeLimit: 600,
//   frameLimit: 0,
//   autoSaveTime: 0,
// });

const Scene = () => {

  const three = useThree();

  // const useTexture('/textures/canary_wharf.jpg');
  const textures = useTexture([
    '/textures/studio-2.jpg',
    '/textures/canary_wharf.jpg',
  ]);

  const gltfScene = useGLTF('/scene.glb');
  const { nodes, animations } = gltfScene;
  const rigAnimClip = animations.find(({ name }) => name === 'Action');
  const playing = useStore((state) => state.playing);
  const mutation = useStore((state) => state.mutation);
  const assets = useStore((state) => state.assets);
  const stage4Visible = useStore((state) => state.stage4Visible);
  const setStates = useStore((state) => state.setStates);

  useEffect(() => {
    assets.textures = textures;
  }, [textures]);

  useEffect(() => {
    if (playing) {
      three.gl.compile(three.scene, three.camera);
    }
  }, [playing]);

  // const camera = useRef();
  const {
    camera,
    deviceOrientationMax,
    mouseMax,
  } = useControls();

  const rig = useRef();
  const rigInner = useRef();
  useAnimation({
    root: rig,
    clip: rigAnimClip,
    playing: !stage4Visible,
  });

  useSound();

  useFrame((state, delta) => {
    if (playing) mutation.frame++;
    setStates();
    // console.log(mutation.frame);
    const { gl, scene, camera: glCamera } = state;
    gl.render(scene, glCamera);
    // Uncomment below to capture
    // recorder.capture(gl.domElement);
  });

  // For AudioListener to be added upon play click
  useEffect(() => { useStore.setState({ camera }); }, []);

  return (
    <>

      <Effects />

      <group ref={rig}>
        <group rotation={[-Math.PI / 2, 0, 0]} ref={rigInner}>
          <PerspectiveCamera
            ref={camera}
            makeDefault
            near={0.01}
            fov={80}
          />
        </group>
      </group>

      <Stage0 gltfScene={gltfScene} rig={rig} />
      <Stage1 gltfScene={gltfScene} />
      <Stage2 gltfScene={gltfScene} rig={rig} />
      <Stage3 gltfScene={gltfScene} rigInner={rigInner} />
      <Stage4 gltfScene={gltfScene} rig={rig} />

    </>
  );
};

useGLTF.preload('/scene.glb');

export default Scene;

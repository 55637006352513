/* eslint-disable no-mixed-operators */
import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';

const Light3 = ({
  intensity,
}) => {

  return (
    <>
      <pointLight
        position={[7.8, 1.5, -105]}
        intensity={intensity * 1}
        distance={5}
      />
    </>

  );
};

export default Light3;

/* eslint-disable no-param-reassign */
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import useStore from '../utils/store';

const useStandardMaterial = ({
  onBeforeCompile,
  update,
  ...materialProps
}) => {
  const shaderRef = useRef();
  useFrame(update);
  return useMemo(() => {
    const material = new THREE.MeshStandardMaterial({
      ...materialProps,
    });
    material.onBeforeCompile = onBeforeCompile;
    return [material, shaderRef];
  }, []);
};

export default useStandardMaterial;

/* eslint-disable no-param-reassign */
import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';

// import { Environment } from "@react-three/drei"
// import Model from "./Model"
// import Overlay from "./Overlay"
// import drei from '@react-three/drei';
import { useGLTF, useAnimations, PerspectiveCamera } from '@react-three/drei';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useThree, useFrame } from '@react-three/fiber';
import useStore from '../utils/store';
import { almostUnitIdentity, smoothstep } from '../utils/helpers';
import Fire from './Fire';
import useAnimation from '../hooks/useAnimation';
import BlenderMesh from './BlenderMesh';
import * as materials from '../utils/materials';

const quaternion = new THREE.Quaternion();

const ShameCamcorder = ({
  gltfScene: { nodes, animations },
  frame,
}) => {

  const {
    Kim, Camcorder, CamcorderLens, CamcorderInner,
  } = nodes;

  const shameCam = useRef();
  const shameCamRig = useRef();
  const light = useRef();

  const { gl, scene, camera } = useThree();
  const shameCamFollows = useStore((state) => state.shameCamFollows);
  const mutation = useStore((state) => state.mutation);
  const stage3Visible = useStore((state) => state.stage3Visible);

  const KimHeadAnimClip = animations.find(({ name }) => (name === 'KimHeadAction'));
  const KimFaceAnimClip = animations.find(({ name }) => (name === 'KimFaceAction'));

  const kimRef = useRef();
  useAnimation({
    root: kimRef,
    clip: KimHeadAnimClip,
    playing: stage3Visible,
    loop: true,
  });
  useAnimation({
    root: kimRef,
    clip: KimFaceAnimClip,
    playing: stage3Visible,
    loop: true,
  });

  useFrame(() => {
    let lightIntensity;
    if (stage3Visible) {
      lightIntensity = smoothstep(mutation.frame, [4670, 4730]) * 1.0;
    } else {
      lightIntensity = 0;
    }
    light.current.intensity = lightIntensity;

    // Shamecam follows rig
    if (shameCamFollows) {
      const shameCamFocus = almostUnitIdentity(mutation.frame, [5500, 6300]);
      shameCam.current.position.z = -0.34 + shameCamFocus * 0.226;
      kimRef.current.position.z = -0.09 + shameCamFocus * 0.17;
      quaternion.copy(camera.quaternion);
      quaternion.multiplyQuaternions(
        shameCamRig.current.quaternion,
        quaternion.conjugate(),
      );
      shameCamRig.current.quaternion.slerp(camera.quaternion, 0.05 + shameCamFocus * 0.95);
      shameCam.current.quaternion.slerp(quaternion, 1 - shameCamFocus);
    }
  });

  return (

    <group
      ref={shameCamRig}
      rotation={[Math.PI, 0, 0]}
    >
      <>
        <group
          ref={shameCam}
          position={[0, 0, -0.2]}
          visible={shameCamFollows}
        >
          <BlenderMesh
            mesh={Camcorder}
          />
          <BlenderMesh mesh={CamcorderInner} material={materials.camcorderInner} />
          <BlenderMesh mesh={CamcorderLens} material={materials.camcorderLens} />
          <group ref={kimRef}>
            <BlenderMesh
              mesh={Kim}
              renderOrder={2}
            />
          </group>
        </group>
        <pointLight
          position={[1, 1, 1]}
          intensity={0}
          ref={light}
        />
      </>
    </group>

  );
};

export default ShameCamcorder;

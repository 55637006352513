/* eslint-disable no-param-reassign */
/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved */
import * as THREE from 'three';
import { useMemo, useRef } from 'react';

export const pillarMaterial = new THREE.MeshStandardMaterial({
  color: '#fff',
  roughness: 0,
});

export const objectMaterial = new THREE.MeshStandardMaterial({
  color: '#1ff',
  roughness: 0,
});
export const cactus1 = new THREE.MeshStandardMaterial({
  color: '#ff8',
});
export const snail1 = new THREE.MeshStandardMaterial({
  color: '#f90',
  roughness: 0,
});

export const sky1 = new THREE.MeshBasicMaterial({ color: '#000' });
export const ground2 = new THREE.MeshStandardMaterial({ color: '#ff0' });
export const sky2 = new THREE.MeshBasicMaterial({ color: '#0ff' });
export const compton1Front = new THREE.MeshStandardMaterial({
  color: '#fff',
});
export const portal1 = new THREE.MeshStandardMaterial({
  colorWrite: false,
});
export const portal2 = new THREE.MeshStandardMaterial({
  colorWrite: false,
  // color: '#f00',
});

export const camcorderLens = new THREE.MeshStandardMaterial({
  transparent: true,
  color: '#225',
  opacity: 0.9,
  roughness: 0.05,
});

export const camcorderInner = new THREE.MeshBasicMaterial({
  color: '#000',
  side: THREE.DoubleSide,
});

import create from 'zustand';

const useStore = create((set, get) => ({
  playedOnce: false,
  playing: false,

  subtitle: null,
  mutation: {
    lensDistort: 0,
    frame: 0,
  },

  assets: {},
  buffers: [],
  sounds: [],

  setStates: () => {
    const oldStates = get();
    const { frame } = oldStates.mutation;
    const states = {};

    states.stage0Visible = frame < 500;
    // turning visibility off->on initially will cause lag. So keep it on from beginning
    states.stage1Visible = frame < 2140;
    states.stage2Visible = frame > 1650 && frame < 4720;
    states.stage3Visible = frame > 1650 && frame < 6300;
    states.shameCamFollows = frame > 5500 && frame < 6300;
    states.stage4Visible = frame > 6360;

    if (oldStates.playing && frame >= 6840) {
      states.playing = false;
    }

    const needsUpdate = Object.entries(states).some(([key, value]) => {
      if (oldStates[key] !== value) return true;
      return false;
    });
    if (needsUpdate) set(states);
  },

}));

export default useStore;

/** @jsxImportSource @emotion/react */
import React, {
  Suspense, useCallback, useEffect, useRef, useState,
} from 'react';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls';
import { css } from '@emotion/react';
import { useSpring, a, useTransition } from 'react-spring';

import {
  useGLTF, useAnimations, PerspectiveCamera,
  useProgress,
  // DeviceOrientationControls,
} from '@react-three/drei';

import useStore from '../utils/store';

const styles = {

  overlay: () => css`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 1;
    background: #000;
  `,

  layer: () => css`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  `,

  startButton: () => css`
    pointer-events: all;
    width: 19vh;
    height: 19vh;
    padding: 4.5vh;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s;
    transform: scale(0.8);
    @media (hover: hover) {
      &:hover {
        > svg {
          fill: #fff;
        }
      }
    }
    > svg {
      transition: fill 0.2s, transform 0.2s;
      margin-left: 2vh;
    }
    &:active {
      transform: scale(0.73);
      > svg {
        fill: #fff;
        /* transform: scale(1); */
      }
    }
  `,

};

const Intro = () => {

  const {
    progress, item,
  } = useProgress();

  const [loaded, setLoaded] = useState(false);

  const [fakeProgress, setFakeProgress] = useState(0);
  useEffect(() => {
    setFakeProgress(0.5);
  }, []);

  const playing = useStore((state) => state.playing);
  const playedOnce = useStore((state) => state.playedOnce);
  const buffers = useStore((state) => state.buffers);

  const springProgress = useSpring({
    to: { x: (progress / 100 + fakeProgress) / (1 + fakeProgress) },
    config: { mass: 1, tension: 120, friction: 26 },
    onRest: ({ value }) => {
      if (value.x >= 1) setLoaded(true);
    },
  });
  const springLoaded = useSpring({
    to: { x: loaded ? 1 : 0 },
  });
  const transOverlay = useTransition(!playing && !playedOnce, {
    from: { x: 1 },
    enter: { x: 1 },
    leave: { x: 0 },
    config: {
      mass: 1, tension: 200, friction: 16, clamp: true,
    },
    delay: 800,
  });
  const transLoading = useTransition(!loaded, {
    from: { x: 1 },
    enter: { x: 1 },
    leave: { x: 0 },
    config: { mass: 1, tension: 140, friction: 26 },
  });
  const transStartButton = useTransition(loaded, {
    from: { x: 0 },
    enter: { x: 1 },
    leave: { x: 0 },
    config: { mass: 1, tension: 120, friction: 26 },
    delay: 300,
  });

  useEffect(() => {
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load('/sounds/sound1.mp3', (buffer) => {
      buffers[0] = buffer;
    });
    audioLoader.load('/sounds/galore-trimmed.mp3', (buffer) => {
      buffers[1] = buffer;
    });
    audioLoader.load('/sounds/tunnel.mp3', (buffer) => {
      buffers[2] = buffer;
    });
  }, []);

  const handleStart = useCallback(() => {
    if (window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function') {
      window.DeviceOrientationEvent.requestPermission().then((response) => {
        if (response === 'granted') {
          useStore.setState({
            playing: true,
            playedOnce: true,
          });
        }
      }).catch((error) => {
        console.error('THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error);
      });
    } else {
      useStore.setState({
        playing: true,
        playedOnce: true,
      });
    }
    // This solves audio permission problem for iOS
    // const listener = new THREE.AudioListener();
    const listener = new THREE.AudioListener();
    // eslint-disable-next-line no-shadow
    const { camera, buffers } = useStore.getState();
    camera.current.add(listener);

    const sounds = buffers.map((buffer) => {
      const sound = new THREE.Audio(listener);
      sound.setBuffer(buffer);
      sound.setLoop(true);
      sound.play();
      sound.pause();
      return sound;
    });

    useStore.setState({ sounds });

    // const sound = new THREE.Audio(listener);
    // sound.setBuffer(buffer);
    // useStore.setState({ listener, sound });
    // sound.play();
    // sound.pause();
    // setTimeout(() => {
    //   sounds[0].play();

    // }, 2000);
    // sound.play();
    // setTimeout(() => {
    //   const audioLoader = new THREE.AudioLoader();
    //   audioLoader.load('/sounds/galore-trimmed.mp3', (buffer) => {
    //     console.log('LOADED!');
    //     const sound = new THREE.Audio(listener);
    //     sound.setBuffer(buffer);
    //     sound.play();
    //   });
    // }, 3000);
    // const { listener, buffer } = useStore.getState();
    // const sound = new THREE.Audio(listener);
    // sound.setBuffer(buffer);
    // sound.play();
  }, []);

  return (
    <>
      {transOverlay(({ x }, item) => item && (
        <a.div
          css={styles.overlay()}
          style={{ transform: x.to((x) => `scale(${x})`) }}
        >
          <div
            css={styles.layer()}
          >
            <a.svg
              xmlns="http://www.w3.org/2000/svg"
              width="20vh"
              viewBox="0 0 34 34"
              fill="transparent"
              strokeWidth="1.2"
              style={{ transform: springLoaded.x.to((x) => `rotate(-90deg) scale(${1 - x * 0.1})`) }}
            >
              <circle
                stroke="#222"
                cx="17"
                cy="17"
                r="15"
              />
              {transLoading(({ x }, item) => item && (
              <a.circle
                stroke="#fff"
                // strokeLinecap="round"
                // strokeLinejoin="round"
                strokeLinecap="square"
                strokeDasharray={94}
                strokeDashoffset={springProgress.x.to((x) => (1 - x) * 94)}
                cx="17"
                cy="17"
                r="15"
                style={{ opacity: x.to((x) => x) }}
              />
              ))}
            </a.svg>
          </div>
            {transStartButton(({ x }, item) => item && (
              <div css={styles.layer()}>
                <a.button
                  type="button"
                  css={styles.startButton()}
                  onClick={handleStart}
                  style={{ opacity: x.to((x) => x) }}
                >
                  <svg
                    height="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 108.54 128"
                    fill="#bbb"
                  >
                    <polygon points="1.86 2.17 1.86 125.83 106.68 64 1.86 2.17" />
                  </svg>
                </a.button>
              </div>
            ))}
        </a.div>

      ))}
    </>
  );
};

export default Intro;

/* eslint-disable no-mixed-operators */
import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';

const Light2 = ({
  intensity,
}) => {

  const target = useRef();

  return (
    <>
      <spotLight
        position={[-200, 200, -75.5]}
        angle={Math.PI * 13 / 360}
        target={target.current}
        penumbra={0}
        intensity={intensity * 1}
        distance={1000}
      />
      <spotLight
        position={[200, 200, -75.5]}
        angle={Math.PI * 13 / 360}
        target={target.current}
        penumbra={0}
        intensity={intensity * 2}
        distance={1000}
        castShadow
      />
      <points ref={target} position={[0, 0, -75.5]} />
    </>

  );
};

export default Light2;

/* eslint-disable no-param-reassign */
/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved */
import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
import * as THREE from 'three';

import {
  useGLTF, useAnimations, PerspectiveCamera, useTexture,
} from '@react-three/drei';
import { useFrame, createPortal, useThree } from '@react-three/fiber';
import BlenderMesh from './BlenderMesh';
import * as materials from '../utils/materials';
import useStore from '../utils/store';
import { smoothstep } from '../utils/helpers';
import Fire from './Fire';
import Light1 from './Light1';
import useAnimation from '../hooks/useAnimation';
import Light0 from './Light0';
import useBasicMaterial from '../hooks/useBasicMaterial';

import customBasicVert from '!!raw-loader!../shaders/customBasic.vert';
import sky0Frag from '!!raw-loader!../shaders/sky0.frag';
import plantVert from '!!raw-loader!../shaders/plant.vert';
import useStandardMaterial from '../hooks/useStandardMaterial';

const quaternion = new THREE.Quaternion();

const Stage0 = ({
  gltfScene: { nodes, animations },
  rig,
}) => {

  const mutation = useStore((state) => state.mutation);
  const assets = useStore((state) => state.assets);
  const { clock } = useThree();
  const texture = assets.textures?.[0];

  const title1Ref = useRef();
  const title2Ref = useRef();
  const title3Ref = useRef();
  const object1Ref = useRef();
  const object2Ref = useRef();
  const object3Ref = useRef();

  const {
    Sky0,
    Title1, Title2, Title3,
    Plant1, Plant2, Plant3,
    Pillar1, Pillar2, Pillar3,
    Ring1, Ring2, Ring3,
  } = nodes;

  const stage0Visible = useStore((state) => state.stage0Visible);

  const titleMaterial = useMemo(() => {
    if (!texture) return null;
    const material = new THREE.MeshStandardMaterial({
      color: '#dff',
      metalness: 0.6,
      roughness: 0.40,
    });
    texture.mapping = THREE.EquirectangularReflectionMapping;
    material.envMap = texture;
    return material;
  }, [texture]);

  const [skyMaterial, skyShader] = useBasicMaterial({
    onBeforeCompile: (shader) => {
      skyShader.current = shader;
      shader.uniforms = {
        ...shader.uniforms,
        time: { value: 0 },
        camPosition: { value: new THREE.Vector3(0, 0, 0) },
      };
      shader.vertexShader = customBasicVert;
      shader.fragmentShader = sky0Frag;
    },
    update: () => {
      if (skyShader.current?.uniforms) {
        const time = clock.getElapsedTime();
        skyShader.current.uniforms.time.value = time;
        skyShader.current.uniforms.camPosition.value.copy(rig.current?.position);
      }
    },
    transparent: true,
    side: THREE.DoubleSide,
  });

  const [plantMaterial, plantShader] = useStandardMaterial({
    onBeforeCompile: (shader) => {
      plantShader.current = shader;
      shader.uniforms = {
        ...shader.uniforms,
        time: { value: 0 },
        camPosition: { value: new THREE.Vector3(0, 0, 0) },
      };
      shader.vertexShader = plantVert;
    },
    update: () => {
      if (plantShader.current?.uniforms) {
        const time = clock.getElapsedTime();
        plantShader.current.uniforms.time.value = time;
      }
    },
    color: '#003a00',
    roughness: 0.35,
  });

  useFrame(() => {
    const { frame } = mutation;
    const time = clock.getElapsedTime();
    if (title1Ref.current && title2Ref.current && title3Ref.current) {
      title1Ref.current.rotation.z = Math.sin(time * 0.8) * 0.15;
      title2Ref.current.rotation.z = Math.sin(time * 0.8) * 0.15;
      title3Ref.current.rotation.z = Math.sin(time * 0.8) * 0.15;
    }
  });

  return (
    <>
      <Light0 intensity={stage0Visible ? 1 : 0} />
      {stage0Visible && (
        <>
          <BlenderMesh mesh={Sky0} material={skyMaterial} />
          <BlenderMesh mesh={Title1} material={titleMaterial} ref={title1Ref} />
          <BlenderMesh mesh={Title2} material={titleMaterial} ref={title2Ref} />
          <BlenderMesh mesh={Title3} material={titleMaterial} ref={title3Ref} />
          <BlenderMesh mesh={Plant1} material={plantMaterial} />
          <BlenderMesh mesh={Plant2} material={plantMaterial} />
          <BlenderMesh mesh={Plant3} material={plantMaterial} />
          <BlenderMesh mesh={Ring1} material={titleMaterial} />
          <BlenderMesh mesh={Ring2} material={titleMaterial} />
          <BlenderMesh mesh={Ring3} material={titleMaterial} />
          <BlenderMesh mesh={Pillar1} material={materials.pillarMaterial} />
          <BlenderMesh mesh={Pillar2} material={materials.pillarMaterial} />
          <BlenderMesh mesh={Pillar3} material={materials.pillarMaterial} />
        </>
      )}
      {/* <group visible={stage0Visible}> */}
      {/* </group> */}
    </>
  );
};

export default Stage0;

/* eslint-disable no-param-reassign */
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import useStore from '../utils/store';

const useVideoTexture = (material, url) => {
  useEffect(() => {
    const video = document.createElement('video');
    document.body.appendChild(video);
    video.setAttribute('loop', true);
    video.setAttribute('playsinline', true);
    video.setAttribute('src', url);
    video.volume = 0;
    video.style = 'display: none;';
    video.play();
    const texture = new THREE.VideoTexture(video);
    material.map = texture;
  }, [material, url]);
};

export default useVideoTexture;

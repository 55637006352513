import * as THREE from 'three';
import React, { useRef, useMemo, useEffect } from 'react';
import { extend, useThree, useFrame } from '@react-three/fiber';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass';
// import { GlitchPass } from './Glitchpass';
// import { WaterPass } from './Waterpass';

// import lensVertexShader from '../utils/shaders/lens-vert.glsl';
// import lensFragmentShader from '../utils/shaders/lens-frag.glsl';

// import { LensDistortionPass } from './LensDistortionPass-old';
import LensDistortionPass from './LensDistortionPass2';
import { WaterPass } from './WaterPass';

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  // WaterPass,
  UnrealBloomPass,
  FilmPass,
  // GlitchPass,
  LensDistortionPass,
  WaterPass,
  // LensDistortionPass,
});

export default function Effects() {
  const composer = useRef();
  const {
    scene, gl, size, camera,
  } = useThree();
  // console.log({});

  const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size]);

  useEffect(() => {
    void composer.current.setSize(size.width, size.height);
  }, [size]);

  useFrame(() => {
    gl.autoClear = true;
    composer.current.render();
  }, 1);

  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <lensDistortionPass attachArray="passes" />
      {/* <waterPass attachArray="passes" factor={0.5} /> */}
      <unrealBloomPass
        attachArray="passes"
        args={[
          aspect,
          1.7, // Strength
          0.4, // Radius
          0.94, // Threshold
        ]}
      />

    </effectComposer>
  );
}

import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import useStore from '../utils/store';

const useAnimation = ({
  clip, playing = true, loop = false, root: rootIn, time: timeOverride,
}) => {

  const mixer = useRef();
  const action = useRef();
  const mutation = useStore((state) => state.mutation);

  const root = rootIn ?? useRef();

  useEffect(() => {
    if (clip) {
      mixer.current = new THREE.AnimationMixer(root.current);
      action.current = mixer.current.clipAction(clip);
      action.current.setLoop(loop ? THREE.LoopRepeat : THREE.LoopOnce);
      action.current.play();
      if (playing) action.current.setEffectiveTimeScale(1);
      else action.current.setEffectiveTimeScale(0);
    }
  }, [clip, playing]);

  useFrame((state, delta) => {
    const time = timeOverride ?? 1 / 60 * mutation.frame;
    mixer.current.setTime(time);
  });

  return {
    root,
  };
};

export default useAnimation;

/* eslint-disable no-param-reassign */
import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';

// import { Environment } from "@react-three/drei"
// import Model from "./Model"
// import Overlay from "./Overlay"
import { useGLTF, useAnimations, PerspectiveCamera } from '@react-three/drei';
// import drei from '@react-three/drei';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useFrame } from '@react-three/fiber';
// import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

const BlenderMesh = React.forwardRef(({
  mesh,
  material: customMaterial,
  position: customPosition,
  depthTest = true,
  depthWrite = true,
  children,
  ...rest
}, ref) => {

  const {
    position,
    rotation,
    scale,
    geometry,
    material: blenderMaterial,
    ...blenderRest
  } = mesh;

  const material = useMemo(() => {

    const temp = customMaterial || blenderMaterial;
    temp.depthTest = depthTest;
    temp.depthWrite = depthWrite;

    return temp;
  }, [customMaterial, depthTest, depthWrite]);

  return (
    <mesh
      ref={ref}
      position={customPosition || position}
      rotation={rotation}
      scale={scale}
      geometry={geometry}
      material={material}
      {...blenderRest}
      {...rest}
    >
      {children}
    </mesh>
  );
});

export default BlenderMesh;

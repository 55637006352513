import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
import * as THREE from 'three';

import {
  useGLTF, useAnimations, PerspectiveCamera, useTexture,
} from '@react-three/drei';
import { useFrame, createPortal, useThree } from '@react-three/fiber';
import BlenderMesh from './BlenderMesh';
import * as materials from '../utils/materials';
import ShameCamcorder from './ShameCamcorder';
import Light3 from './Light3';
import useStore from '../utils/store';

const Stage3 = ({
  gltfScene,
  rigInner,
}) => {

  const { nodes, animations } = gltfScene;
  const stage3Visible = useStore((state) => state.stage3Visible);
  const assets = useStore((state) => state.assets);
  const texture = assets.textures?.[1];

  const slideMaterial = useMemo(() => {
    if (!texture) return null;
    texture.mapping = THREE.EquirectangularReflectionMapping;
    const material = new THREE.MeshStandardMaterial({
      color: '#aaa',
      metalness: 0.2,
      roughness: 0.2,
    });
    material.envMap = texture;
    return material;
  }, [texture]);

  const {
    Portal2, Slide,
  } = nodes;

  return (
    <>
      <Light3 intensity={stage3Visible ? 1 : 0} />
      <group visible={stage3Visible}>
        <BlenderMesh mesh={Portal2} material={materials.portal2} renderOrder={3} />
        <BlenderMesh mesh={Slide} material={slideMaterial} renderOrder={2} />
      </group>
      {rigInner.current && createPortal((
        <ShameCamcorder
          gltfScene={gltfScene}
        />
      ), rigInner.current)}
    </>
  );
};

export default Stage3;

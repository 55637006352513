/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';
import extendStyles from '../utils/styles';

// import C from '../utils/constants';

// Normalize Css
// See https://github.com/necolas/normalize.css for normalize specs
// import 'normalize.css';

// Meyer's Reset Css
const reset = css`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;

const global = () => css`

  /* Custom Reset */
  svg {
    display: block;
  }
  a { text-decoration: none; color: inherit; }
  a:visited { text-decoration: none; color:inherit; }
  a:hover { text-decoration: none; color:inherit; }
  a:focus { text-decoration: none; color:inherit; }
  a:hover, a:active { text-decoration: none; color:inherit }
  input {
    -webkit-appearance: none;
    border: 1px solid #000;
    &:focus {
      outline: none;
    }
  }
  button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: inherit;
  }

  ::selection { background: #eee; }

  * {
    box-sizing: border-box;
  }
  html {
    display: relative;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
  }
  body {
    height: 100%;
    min-height: 100%;
  }
  #root {
    position: relative;
    min-height: 100%;
    height: 100%;
    word-break: keep-all;
  }
`;

const GlobalStyle = () => {

  return (
    <>
      <Global styles={reset} />
      <Global styles={global()} />
      <span css={css`
        font-family: 'Roboto', sans-serif;
        display: block;
        width: 0;
        overflow: hidden;
        position: fixed;
        top: 0; left: 0;

      `}
      >
        Font preload
      </span>
    </>
  );

};

export default GlobalStyle;

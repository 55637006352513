/* eslint-disable no-param-reassign */
/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved */
import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
import * as THREE from 'three';

import { useGLTF, useAnimations, PerspectiveCamera } from '@react-three/drei';
import { useFrame, createPortal, useThree } from '@react-three/fiber';
import BlenderMesh from './BlenderMesh';
import * as materials from '../utils/materials';
import useStore from '../utils/store';
import { smoothstep } from '../utils/helpers';
import Fire from './Fire';
import Light2 from './Light2';
import useBasicMaterial from '../hooks/useBasicMaterial';
import useStandardMaterial from '../hooks/useStandardMaterial';

import customBasicVert from '!!raw-loader!../shaders/customBasic.vert';
import sky2Frag from '!!raw-loader!../shaders/sky2.frag';
import ground2Frag from '!!raw-loader!../shaders/ground2.frag';
// import ground2PhysicalFrag from '!!raw-loader!../shaders/ground2-physical.frag';
import flagVert from '!!raw-loader!../shaders/flag.vert';
import flagFrag from '!!raw-loader!../shaders/flag.frag';
import shellVert from '!!raw-loader!../shaders/shell.vert';
import cactusVert from '!!raw-loader!../shaders/cactus.vert';

const Stage2 = ({
  gltfScene: { nodes },
  rig,
}) => {

  const {
    Sky2, Ground2,
    Snail1Body, Snail1Shell,
    Snail2Body, Snail2Shell,
    Snail3Body, Snail3Shell,
    Snail4Body, Snail4Shell,
    Cactus1, Cactus2, Cactus3, Cactus4, Cactus5,
    Flag1, Flag2, Flag3,
  } = nodes;
  const { clock } = useThree();

  const stage2Visible = useStore((state) => state.stage2Visible);
  const cacti = useRef([]);
  const shells = useRef([]);

  const [skyMaterial, skyShader] = useBasicMaterial({
    onBeforeCompile: (shader) => {
      skyShader.current = shader;
      shader.uniforms = {
        ...shader.uniforms,
        time: { value: 0 },
        camPosition: { value: new THREE.Vector3(0, 0, 0) },
      };
      shader.vertexShader = customBasicVert;
      shader.fragmentShader = `
// +>
varying vec3 vPosition;
varying vec3 vViewPosition;
varying vec3 vWorldPosition;
uniform float time;
uniform vec3 camPosition;

// float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
// vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
// vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}
// float noise(vec3 p){
//     vec3 a = floor(p);
//     vec3 d = p - a;
//     d = d * d * (3.0 - 2.0 * d);
//     vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
//     vec4 k1 = perm(b.xyxy);
//     vec4 k2 = perm(k1.xyxy + b.zzww);
//     vec4 c = k2 + a.zzzz;
//     vec4 k3 = perm(c);
//     vec4 k4 = perm(c + 1.0);
//     vec4 o1 = fract(k3 * (1.0 / 41.0));
//     vec4 o2 = fract(k4 * (1.0 / 41.0));
//     vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
//     vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);
//     return o4.y * d.y + o4.x * (1.0 - d.y);
// }

//  Simplex 4D Noise 
//  by Ian McEwan, Ashima Arts
//
vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
float permute(float x){return floor(mod(((x*34.0)+1.0)*x, 289.0));}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
float taylorInvSqrt(float r){return 1.79284291400159 - 0.85373472095314 * r;}
vec4 grad4(float j, vec4 ip){
  const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
  vec4 p,s;
  p.xyz = floor( fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
  p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
  s = vec4(lessThan(p, vec4(0.0)));
  p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www; 
  return p;
}

float snoise(vec4 v){
  const vec2  C = vec2( 0.138196601125010504,  // (5 - sqrt(5))/20  G4
                        0.309016994374947451); // (sqrt(5) - 1)/4   F4
// First corner
  vec4 i  = floor(v + dot(v, C.yyyy) );
  vec4 x0 = v -   i + dot(i, C.xxxx);
// Other corners
// Rank sorting originally contributed by Bill Licea-Kane, AMD (formerly ATI)
  vec4 i0;
  vec3 isX = step( x0.yzw, x0.xxx );
  vec3 isYZ = step( x0.zww, x0.yyz );
//  i0.x = dot( isX, vec3( 1.0 ) );
  i0.x = isX.x + isX.y + isX.z;
  i0.yzw = 1.0 - isX;
//  i0.y += dot( isYZ.xy, vec2( 1.0 ) );
  i0.y += isYZ.x + isYZ.y;
  i0.zw += 1.0 - isYZ.xy;
  i0.z += isYZ.z;
  i0.w += 1.0 - isYZ.z;
  // i0 now contains the unique values 0,1,2,3 in each channel
  vec4 i3 = clamp( i0, 0.0, 1.0 );
  vec4 i2 = clamp( i0-1.0, 0.0, 1.0 );
  vec4 i1 = clamp( i0-2.0, 0.0, 1.0 );
  //  x0 = x0 - 0.0 + 0.0 * C 
  vec4 x1 = x0 - i1 + 1.0 * C.xxxx;
  vec4 x2 = x0 - i2 + 2.0 * C.xxxx;
  vec4 x3 = x0 - i3 + 3.0 * C.xxxx;
  vec4 x4 = x0 - 1.0 + 4.0 * C.xxxx;
  // Permutations
  i = mod(i, 289.0); 
  float j0 = permute( permute( permute( permute(i.w) + i.z) + i.y) + i.x);
  vec4 j1 = permute( permute( permute( permute (
             i.w + vec4(i1.w, i2.w, i3.w, 1.0 ))
           + i.z + vec4(i1.z, i2.z, i3.z, 1.0 ))
           + i.y + vec4(i1.y, i2.y, i3.y, 1.0 ))
           + i.x + vec4(i1.x, i2.x, i3.x, 1.0 ));
  // Gradients
  // ( 7*7*6 points uniformly over a cube, mapped onto a 4-octahedron.)
  // 7*7*6 = 294, which is close to the ring size 17*17 = 289.
  vec4 ip = vec4(1.0/294.0, 1.0/49.0, 1.0/7.0, 0.0) ;
  vec4 p0 = grad4(j0,   ip);
  vec4 p1 = grad4(j1.x, ip);
  vec4 p2 = grad4(j1.y, ip);
  vec4 p3 = grad4(j1.z, ip);
  vec4 p4 = grad4(j1.w, ip);
  // Normalise gradients
  vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
  p0 *= norm.x;
  p1 *= norm.y;
  p2 *= norm.z;
  p3 *= norm.w;
  p4 *= taylorInvSqrt(dot(p4,p4));
  // Mix contributions from the five corners
  vec3 m0 = max(0.6 - vec3(dot(x0,x0), dot(x1,x1), dot(x2,x2)), 0.0);
  vec2 m1 = max(0.6 - vec2(dot(x3,x3), dot(x4,x4)            ), 0.0);
  m0 = m0 * m0;
  m1 = m1 * m1;
  return 49.0 * ( dot(m0*m0, vec3( dot( p0, x0 ), dot( p1, x1 ), dot( p2, x2 )))
               + dot(m1*m1, vec2( dot( p3, x3 ), dot( p4, x4 ) ) ) ) ;
}
// <+

uniform vec3 diffuse;
uniform float opacity;
#ifndef FLAT_SHADED
  varying vec3 vNormal;
#endif
#include <common>
#include <dithering_pars_fragment>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <uv2_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <aomap_pars_fragment>
#include <lightmap_pars_fragment>
#include <envmap_common_pars_fragment>
#include <envmap_pars_fragment>
#include <cube_uv_reflection_fragment>
#include <fog_pars_fragment>
#include <specularmap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>
void main() {
  #include <clipping_planes_fragment>
  vec4 diffuseColor = vec4( diffuse, opacity );
  #include <logdepthbuf_fragment>
  #include <map_fragment>
  #include <color_fragment>
  #include <alphamap_fragment>
  #include <alphatest_fragment>
  #include <specularmap_fragment>
  ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
  // accumulation (baked indirect lighting only)
  #ifdef USE_LIGHTMAP
    vec4 lightMapTexel= texture2D( lightMap, vUv2 );
    reflectedLight.indirectDiffuse += lightMapTexelToLinear( lightMapTexel ).rgb * lightMapIntensity;
  #else
    reflectedLight.indirectDiffuse += vec3( 1.0 );
  #endif
  // modulation
  #include <aomap_fragment>
  reflectedLight.indirectDiffuse *= diffuseColor.rgb;
  vec3 outgoingLight = reflectedLight.indirectDiffuse;
  #include <envmap_fragment>

  // +>
  vec3 direction = normalize((vWorldPosition - camPosition));
  vec3 color = vec3(0.);
  // color.b = fract(length(vViewPosition.xyz));
  // color.g = fract(direction.x * 10.);
  // color.g = direction.y;
  // color = vec3(pow(noise(vec3(direction.xy * 4. + time, time)), 2.));

  float mix1 = sin(
      length(direction.xz) * 30. + (sin(time * 4.) + time * 5.)
    ) * 0.5 + 0.5;
  vec3 col1 = mix(
    vec3(0., 0.9*(sin(time)*.5+.5), 1.),
    vec3(0., 1., 1.*(sin(time)*.5+.5)),
    mix1
  );

  float angle2 = atan(direction.z,  direction.x);
  float mix2 = cos(angle2 * 10. + sin(direction.y * 14. - time * 3.) * 7.);
  vec3 col2 = mix(
    vec3(1., 1., 0.),
    vec3(1., 0., 0.),
    mix2
  );

  float x = snoise(vec4(direction.xyz * 0.4, time * 0.5));
  color = mix(col1, col2, x);
  gl_FragColor = vec4( color, diffuseColor.a );
  // <+

  // ->
  // gl_FragColor = vec4( outgoingLight, diffuseColor.a );
  // <-
  #include <tonemapping_fragment>
  #include <encodings_fragment>
  #include <fog_fragment>
  #include <premultiplied_alpha_fragment>
  #include <dithering_fragment>
}

      `;
    },
    update: () => {
      if (skyShader.current?.uniforms) {
        const time = clock.getElapsedTime();
        skyShader.current.uniforms.time.value = time;
        skyShader.current.uniforms.camPosition.value.copy(rig.current?.position);
      }
    },
  });

  const [groundMaterial, groundShader] = useStandardMaterial({
    onBeforeCompile: (shader) => {
      groundShader.current = shader;
      shader.uniforms = {
        ...shader.uniforms,
        time: { value: 0 },
        camPosition: { value: new THREE.Vector3(0, 0, 0) },
      };
      shader.vertexShader = customBasicVert;
      shader.fragmentShader = ground2Frag;
    },
    update: () => {
      if (groundShader.current?.uniforms) {
        const time = clock.getElapsedTime();
        groundShader.current.uniforms.time.value = time;
        groundShader.current.uniforms.camPosition.value.copy(rig.current?.position);
      }
    },
    color: '#04f',
    roughness: 0,
  });

  const [shellMaterial, shellShader] = useStandardMaterial({
    onBeforeCompile: (shader) => {
    },
    update: () => {
    },
    side: THREE.DoubleSide,
    color: '#f90',
    roughness: 0,
  });

  const [cactusMaterial, cactusShader] = useStandardMaterial({
    onBeforeCompile: (shader) => {
      cactusShader.current = shader;
      shader.uniforms = {
        ...shader.uniforms,
        time: { value: 0 },
      };
      // shader.vertexShader = cactusVert;
    },
    update: () => {
      if (cactusShader.current?.uniforms) {
        const time = clock.getElapsedTime();
        cactusShader.current.uniforms.time.value = time;
      }
    },
    color: '#4ff',
    roughness: 0,
  });

  const [flagMaterial, flagShader] = useBasicMaterial({
    onBeforeCompile: (shader) => {
      flagShader.current = shader;
      shader.uniforms = {
        ...shader.uniforms,
        time: { value: 0 },
        camPosition: { value: new THREE.Vector3(0, 0, 0) },
      };
      shader.vertexShader = flagVert;
      shader.fragmentShader = flagFrag;
    },
    update: () => {
      if (flagShader.current?.uniforms) {
        const time = clock.getElapsedTime();
        flagShader.current.uniforms.time.value = time;
        flagShader.current.uniforms.camPosition.value.copy(rig.current?.position);
      }
    },
    side: THREE.DoubleSide,
  });

  useFrame(() => {
    const time = clock.getElapsedTime();
    shells.current.forEach((shell, idx) => {
      if (!shell) return;
      shell.scale.setY(
        1
        + Math.max(Math.sin(time * 14.0 + idx), 0) * 0.3,
      );
    });
    cacti.current.forEach((cactus, idx) => {
      if (!cactus) return;
      cactus.scale.setY(
        1
        + Math.max(Math.sin(time * 14.0 + idx), 0) * 0.2,
      );
    });
  });

  return (
    <>
      <Light2 intensity={stage2Visible ? 1 : 0} />
      <>
        {stage2Visible && (
        <>
          <BlenderMesh mesh={Sky2} material={skyMaterial} renderOrder={3} depthWrite={false} />
          <BlenderMesh mesh={Ground2} material={groundMaterial} renderOrder={4} />
          <BlenderMesh mesh={Snail1Body} renderOrder={4} material={materials.snail1} />
          <BlenderMesh mesh={Snail1Shell} renderOrder={4} material={shellMaterial} ref={(el) => { shells.current[0] = el; }} />
          <BlenderMesh mesh={Snail2Body} renderOrder={4} material={materials.snail1} />
          <BlenderMesh mesh={Snail2Shell} renderOrder={4} material={shellMaterial} ref={(el) => { shells.current[1] = el; }} />
          <BlenderMesh mesh={Snail3Body} renderOrder={4} material={materials.snail1} />
          <BlenderMesh mesh={Snail3Shell} renderOrder={4} material={shellMaterial} ref={(el) => { shells.current[2] = el; }} />
          <BlenderMesh mesh={Snail4Body} renderOrder={4} material={materials.snail1} />
          <BlenderMesh mesh={Snail4Shell} renderOrder={4} material={shellMaterial} ref={(el) => { shells.current[3] = el; }} />
          <BlenderMesh mesh={Cactus1} scale={1} renderOrder={4} material={cactusMaterial} ref={(el) => { cacti.current[0] = el; }} />
          <BlenderMesh mesh={Cactus2} scale={1} renderOrder={4} material={cactusMaterial} ref={(el) => { cacti.current[1] = el; }} />
          <BlenderMesh mesh={Cactus3} scale={1} renderOrder={4} material={cactusMaterial} ref={(el) => { cacti.current[2] = el; }} />
          <BlenderMesh mesh={Cactus4} scale={1} renderOrder={4} material={cactusMaterial} ref={(el) => { cacti.current[3] = el; }} />
          <BlenderMesh mesh={Cactus5} scale={1} renderOrder={4} material={cactusMaterial} ref={(el) => { cacti.current[4] = el; }} />
          <BlenderMesh mesh={Flag1} renderOrder={4} material={flagMaterial} />
          <BlenderMesh mesh={Flag2} renderOrder={4} material={flagMaterial} />
          <BlenderMesh mesh={Flag3} renderOrder={4} material={flagMaterial} />

        </>
        )}
      </>
    </>
  );
};

export default Stage2;

import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';

const EPS = 0.000001;
const z = new THREE.Vector3(0, 0, 1);
const euler = new THREE.Euler();
const quaternionFront = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, 0));
const quaternion = new THREE.Quaternion();
const q0 = new THREE.Quaternion();
const q1 = new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5));

const useControls = () => {

  const camera = useRef();
  const deviceOrientationMax = useRef(1);
  const mouseMax = useRef([
    Math.PI, // / 8,
    Math.PI, // / 8,
  ]);

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) setIsMobile(true);
    else setIsMobile(false);
  }, []);

  const _q0 = useRef(new THREE.Quaternion());
  const _q1 = useRef(new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5))); // - PI/2 around the x-axis

  const deviceOrientation = useRef({});
  const screenOrientation = useRef(0);
  const lastQuaternion = useRef(new THREE.Quaternion());
  const mousePos = useRef([0, 0]); // Both -1 <-> 1

  const rotateCameraFromDeviceOrientation = useCallback(() => {

    if (camera.current.rotation.order !== 'YXZ') camera.current.rotation.reorder('YXZ');

    const device = deviceOrientation.current;

    const alpha = device.alpha ? THREE.MathUtils.degToRad(device.alpha) : 0; // Z
    const beta = device.beta ? THREE.MathUtils.degToRad(device.beta) : 0; // X'
    const gamma = device.gamma ? THREE.MathUtils.degToRad(device.gamma) : 0; // Y''

    const orient = screenOrientation.current ? THREE.MathUtils.degToRad(screenOrientation.current) : 0; // O
    euler.set(beta, alpha, -gamma, 'YXZ'); // 'ZXY' for the device, but 'YXZ' for us
    quaternion.setFromEuler(euler); // orient the device
    quaternion.multiply(_q1.current); // camera looks out the back of the device, not the top
    quaternion.multiply(_q0.current.setFromAxisAngle(z, -orient)); // adjust for screen orientation
    quaternion.slerp(quaternionFront, 1 - deviceOrientationMax.current);

    camera.current.quaternion.slerp(quaternion, 0.2);
    // camera.current.quaternion.slerp()

    if (8 * (1 - lastQuaternion.current.dot(camera.current.quaternion)) > EPS) {
      lastQuaternion.current.copy(camera.current.quaternion);
    }
  }, []);

  const rotateCameraFromMouse = useCallback(() => {

    const y1 = -mousePos.current[0] * mouseMax.current[0];
    const x1 = -mousePos.current[1] * mouseMax.current[1];
    const y = THREE.MathUtils.lerp(euler.y, y1, 0.1);
    const x = THREE.MathUtils.lerp(euler.x, x1, 0.1);

    euler.set(x, y, 0, 'YXZ'); // 'ZXY' for the device, but 'YXZ' for us
    camera.current.quaternion.setFromEuler(euler); // orient the device
  }, []);

  const onDeviceOrientationChange = useCallback((event) => {
    deviceOrientation.current = event;
  }, []);

  const onScreenOrientationChange = useCallback(() => {
    screenOrientation.current = window.orientation || 0;
  }, []); // The angles alpha, beta and gamma form a set of intrinsic Tait-Bryan angles of type Z-X'-Y''

  const onMousemove = useCallback((e) => {
    mousePos.current[0] = (e.clientX / window.innerWidth) * 2 - 1;
    mousePos.current[1] = (e.clientY / window.innerHeight) * 2 - 1;
  }, []);

  useEffect(() => {
    // Device type yet unknown
    if (isMobile == null) return () => {}; // returning function for eslint consistent return rule

    // Device is mobile
    if (isMobile) {
      if (window.isSecureContext === false) {
        console.error('THREE.DeviceOrientationControls: DeviceOrientationEvent is only available in secure contexts (https)');
      }
      onScreenOrientationChange(); // run once on load
      window.addEventListener('orientationchange', onScreenOrientationChange);
      window.addEventListener('deviceorientation', onDeviceOrientationChange);
      return (() => {
        window.removeEventListener('orientationchange', onScreenOrientationChange);
        window.removeEventListener('deviceorientation', onDeviceOrientationChange);
      });
    }

    // Device is not mobile
    window.addEventListener('mousemove', onMousemove);
    return (() => {
      window.removeEventListener('mousemove', onMousemove);
    });

  }, [isMobile]);

  useFrame(() => {
    if (isMobile == null) return;
    if (!camera.current) return;
    if (isMobile) {
      rotateCameraFromDeviceOrientation();
    } else {
      rotateCameraFromMouse();
    }
  });

  return {
    camera,
    deviceOrientationMax,
    mouseMax,
  };
};

export default useControls;

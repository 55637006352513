/** @jsxImportSource @emotion/react */
import React, {
  Suspense, useCallback, useEffect, useRef, useState,
} from 'react';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls';
import { css } from '@emotion/react';
import { useSpring, a, useTransition } from 'react-spring';

import useStore from '../utils/store';

const styles = {

  overlay: () => css`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    pointer-events: none;
  `,

  subtitleBox: () => css`
    position: absolute;
    width: 100%;
    /* background: red; */
    bottom: 12%;
    display: flex;
    justify-content: center;
    > span {
      background: rgba(100, 100, 100, 0.3);
      color: #fff;
      font-size: 4.5vh;
      font-family: 'Roboto', sans-serif;
      line-height: 1.1;
      padding: 0.2em 0.3em 0.12em;
      border-radius: 0.25em;
      text-align: center;
    }
  `,

};

const Overlay = () => {

  const subtitle = useStore((state) => state.subtitle);

  return (
    <div css={styles.overlay()}>
      {subtitle && (
        <div css={styles.subtitleBox()}>
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default Overlay;

/* eslint-disable jsx-a11y/media-has-caption */
import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';

import Scene from './3D/Scene';
import Intro from './2D/Intro';
import Outro from './2D/Outro';
import Overlay from './2D/Overlay';

function App() {
  return (
    <>
      <Intro />
      <Outro />
      <Overlay />
      <Canvas
        shadows
        linear
        gl={{
          // preserveDrawingBuffer: true,
          // logarithmicDepthBuffer: true, // Prevents mesh edge jittering
          premultipliedAlpha: true,
          autoClear: true,
        }}
        raycaster={{
          computeOffsets: ({ clientX, clientY }) => ({ offsetX: clientX, offsetY: clientY }),
        }}
        onCreated={({ gl }) => {
          gl.setClearColor('#000');
        }}
      >
        <Suspense fallback={null}>
          <Scene />
        </Suspense>
      </Canvas>
    </>
  );
}

export default App;

/* eslint-disable no-param-reassign */
/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved */
import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
import * as THREE from 'three';

import { useGLTF, useAnimations, PerspectiveCamera } from '@react-three/drei';
import { useFrame, createPortal, useThree } from '@react-three/fiber';
import BlenderMesh from './BlenderMesh';
import * as materials from '../utils/materials';
import useStore from '../utils/store';
import { smoothstep } from '../utils/helpers';
import Fire from './Fire';
import useBasicMaterial from '../hooks/useBasicMaterial';

import customBasicVert from '!!raw-loader!../shaders/customBasic.vert';
import sky2Frag from '!!raw-loader!../shaders/sky2.frag';
import useAnimation from '../hooks/useAnimation';

const quaternion = new THREE.Quaternion();

const Stage4 = ({
  gltfScene: { nodes, animations },
  rig,
}) => {

  const {
    KimCheekPuff, KimMouthPucker, KimMouthStretchL, KimMouthUpperUpL,
    Sky4,
  } = nodes;
  const mutation = useStore((state) => state.mutation);
  const stage4Visible = useStore((state) => state.stage4Visible);
  const heads = useRef();
  const light = useRef();
  const { gl, scene, camera } = useThree();

  const kimMouthPuckerRef = useRef();
  const kimMouthStretchLRef = useRef();
  const kimMouthUpperUpLRef = useRef();
  const kimCheekPuffRef = useRef();

  const KimCheekPuffMaterial = useMemo(() => {
    const { material } = KimCheekPuff;
    console.log(material.premultipliedAlpha);
    material.premultipliedAlpha = true;
    console.log(material.premultipliedAlpha);
    return material;
  }, [KimCheekPuff]);
  const KimMouthPuckerMaterial = useMemo(() => {
    const { material } = KimMouthPucker;
    material.premultipliedAlpha = true;
    return material;
  }, [KimMouthPucker]);
  const KimMouthStretchLMaterial = useMemo(() => {
    const { material } = KimMouthStretchL;
    material.premultipliedAlpha = true;
    return material;
  }, [KimMouthStretchL]);
  const KimMouthUpperUpLMaterial = useMemo(() => {
    const { material } = KimMouthUpperUpL;
    material.premultipliedAlpha = true;
    return material;
  }, [KimMouthUpperUpL]);

  const KimMouthPuckerClip = animations.find(({ name }) => (name === 'KimMouthPuckerAction'));
  const KimMouthStretchLClip = animations.find(({ name }) => (name === 'KimMouthStretchLAction'));
  const KimMouthUpperUpLClip = animations.find(({ name }) => (name === 'KimMouthUpperUpLAction'));
  const KimCheekPuffClip = animations.find(({ name }) => (name === 'KimCheekPuffAction'));

  useAnimation({
    root: kimMouthPuckerRef,
    clip: KimMouthPuckerClip,
    playing: stage4Visible,
    loop: true,
  });
  useAnimation({
    root: kimMouthStretchLRef,
    clip: KimMouthStretchLClip,
    playing: stage4Visible,
    loop: true,
  });
  useAnimation({
    root: kimMouthUpperUpLRef,
    clip: KimMouthUpperUpLClip,
    playing: stage4Visible,
    loop: true,
  });
  useAnimation({
    root: kimCheekPuffRef,
    clip: KimCheekPuffClip,
    playing: stage4Visible,
    loop: true,
  });

  const [skyMaterial, skyShader] = useBasicMaterial({
    onBeforeCompile: (shader) => {
      skyShader.current = shader;
      shader.uniforms = {
        ...shader.uniforms,
        time: { value: 0 },
        camPosition: { value: new THREE.Vector3(0, 0, 0) },
      };
      shader.vertexShader = customBasicVert;
      shader.fragmentShader = sky2Frag;
    },
    update: () => {
      if (skyShader.current?.uniforms) {
        skyShader.current.uniforms.time.value = mutation.frame / 6;
        skyShader.current.uniforms.camPosition.value.copy(rig.current?.position);
      }
    },
    side: THREE.DoubleSide,
  });

  useFrame(() => {
    if (stage4Visible) {
      rig.current.position.set(0, 0, 0);
      heads.current.rotation.set(0, mutation.frame * 0.02, 0);
    }
  });

  return (
    <>
      <pointLight
        ref={light}
        position={[0, 0, 0]}
        intensity={stage4Visible ? 2 : 0}
      />
      <group visible={stage4Visible}>
        {/* <BlenderMesh mesh={Sky4} material={skyMaterial} /> */}
        <group ref={heads}>
          <BlenderMesh mesh={KimCheekPuff} material={KimCheekPuffMaterial} ref={kimCheekPuffRef} />
          <BlenderMesh mesh={KimMouthPucker} material={KimMouthPuckerMaterial} ref={kimMouthPuckerRef} />
          <BlenderMesh mesh={KimMouthStretchL} material={KimMouthStretchLMaterial} ref={kimMouthStretchLRef} />
          <BlenderMesh mesh={KimMouthUpperUpL} material={KimMouthUpperUpLMaterial} ref={kimMouthUpperUpLRef} />
        </group>
      </group>
    </>
  );
};

export default Stage4;

import React, {
  Suspense, useEffect, useMemo, useRef,
} from 'react';
import * as THREE from 'three';

import { useGLTF, useAnimations, PerspectiveCamera } from '@react-three/drei';
import { useFrame, createPortal, useThree } from '@react-three/fiber';
import BlenderMesh from './BlenderMesh';
import * as materials from '../utils/materials';
import useStore from '../utils/store';
import { smoothstep } from '../utils/helpers';
import Fire from './Fire';
import Light1 from './Light1';
import useAnimation from '../hooks/useAnimation';
import useVideoTexture from '../hooks/useVideoTexture';

const quaternion = new THREE.Quaternion();

const Stage1 = ({
  gltfScene: { nodes, animations },
}) => {

  const {
    Ground1, Sky1,
    Compton1, Compton1Front, Compton1TextureFront,
    Portal1,
  } = nodes;

  const Portal1AnimClip = animations.find(({ name }) => (name === 'Portal1Action'));
  const compton1AnimClip = animations.find(({ name }) => (name === 'Compton1Action'));
  const compton1FrontAnimClip = animations.find(({ name }) => (name === 'Compton1FrontAction'));

  const stage1Visible = useStore((state) => state.stage1Visible);

  useVideoTexture(materials.compton1Front, '/compton1_2.mp4');

  const { root: portal1 } = useAnimation({
    clip: Portal1AnimClip,
  });
  const { root: compton1 } = useAnimation({
    clip: compton1AnimClip,
  });
  const { root: compton1Front } = useAnimation({
    clip: compton1FrontAnimClip,
  });

  return (
    <>
      {/* Don't swtich visibility */}
      <BlenderMesh mesh={Portal1} material={materials.portal1} ref={portal1} renderOrder={6} />
      <Light1 position={[0, 6, -8]} intensity={stage1Visible ? 1 : 0} />
      <Light1 position={[0, 6, -21]} intensity={stage1Visible ? 1 : 0} flicker />
      <Light1 position={[0, 6, -38]} intensity={stage1Visible ? 1 : 0} />
      <group renderOrder={7} visible={stage1Visible}>
        <BlenderMesh mesh={Ground1} receiveShadow />
        <BlenderMesh mesh={Compton1} ref={compton1} castShadow />
        <BlenderMesh mesh={Compton1Front} ref={compton1Front} castShadow>
          <BlenderMesh mesh={Compton1TextureFront} material={materials.compton1Front} />
        </BlenderMesh>
        <BlenderMesh mesh={Sky1} material={materials.sky1} />
      </group>
    </>
  );
};

export default Stage1;

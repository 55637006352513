/** @jsxImportSource @emotion/react */
import React, {
  Suspense, useCallback, useEffect, useRef, useState,
} from 'react';
// import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls';
import { css } from '@emotion/react';
import { useSpring, a, useTransition } from 'react-spring';

import useStore from '../utils/store';
import { delay } from '../utils/helpers';

const styles = {

  overlay: () => css`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 1;
    background: #000;
  `,

  slideCreditA: () => css`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    flex-direction: column;

    color: #fff;
    font-family: 'Roboto', sans-serif;
    text-align: center;

    .small {
      font-size: 2.3vh;
      line-height: 1.24;
    }
    .large {
      font-size: 3vh;
      line-height: 1.2;
      margin-bottom: 2.1vh;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

  `,

  slideCreditB: () => css`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    flex-direction: column;

    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 3.6vh;
    line-height: 1.23;
    text-align: center;

    .small {
      font-size: 2.3vh;
      line-height: 1.24;
    }
    .large {
      font-size: 3vh;
      line-height: 1.2;
      margin-bottom: 2.1vh;
      &:last-of-type {
        margin-bottom: 0;
      }
    }


  `,

  slideLast: () => css`
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    flex-direction: column;

    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 3.6vh;
    line-height: 1.2;
    text-align: center;

  `,

  role: () => css`
    display: block;
    font-size: 2.1vh;
  `,

  name: () => css`
    display: block;
    font-size: 2.9vh;
    margin-bottom: 1em;
    &:last-of-type {
      margin-bottom: 0;
    }
  `,

  replayButton: () => css`
    pointer-events: all;
    width: 15vh;
    height: 15vh;
    padding: 2vh;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s;
    transform: scale(0.8);
    border: 0.7vh solid #222;
    @media (hover: hover) {
      &:hover {
        > svg {
          fill: #fff;
        }
      }
    }
    > svg {
      width: 100%;
      transition: fill 0.2s, transform 0.2s;
    }
    &:active {
      transform: scale(0.73) rotate(-30deg);
      > svg {
        fill: #fff;
        /* transform: scale(1); */
      }
    }
  `,

  infoButton: () => css`
    pointer-events: all;
    font-size: 3.6vh;

  `,

  info: () => css`
    position: absolute;
    width: 100%;
    height: 100%;
    background: red;
    transition: 0.8s linear;
    transform: translate3d(0, 100%, 0);
    &.open {
      transform: translate3d(0, 0%, 0);
    }
  `,

};

const Outro = () => {

  const [slide, setSlide] = useState(null);

  const playing = useStore((state) => state.playing);
  const playedOnce = useStore((state) => state.playedOnce);
  const mutation = useStore((state) => state.mutation);

  useEffect(async () => {
    if (!playing && playedOnce) {
      await delay(2000, () => { setSlide(0); });
      await delay(5000, () => { setSlide(1); });
      await delay(3000, () => { setSlide(2); });
    }
  }, [playing, playedOnce]);

  const transOverlay = useTransition(!playing && playedOnce, {
    from: { x: 1 },
    enter: { x: 1 },
    leave: { x: 0 },
    config: {
      mass: 1, tension: 200, friction: 16, clamp: true,
    },
    delay: 800,
  });
  const transLastSlide = useTransition(slide === 2, {
    from: { x: 0 },
    enter: { x: 1 },
    leave: { x: 0 },
    config: { mass: 1, tension: 120, friction: 26 },
    delay: 300,
  });

  return (
    <>
      {transOverlay(({ x }, item) => item && (
        <a.div
          css={styles.overlay()}
          style={{ transform: x.to((x) => `scale(${x})`) }}
        >
          {slide === 0 && (
            <div css={styles.slideCreditA()}>
              <span className="small">
                Designed and Developed by
              </span>
              <span className="large">
                KWANG EUN PARK
              </span>
              <span className="small">
                In Collaboration with
              </span>
              <span className="large">
                KIMBERLY
              </span>
              <span className="small">
                Based on Sketches by
              </span>
              <span className="large">
                EUNSOL LEE
              </span>
            </div>
          )}
          {slide === 1 && (
            <div css={styles.slideCreditB()}>
              <span className="small">
                Music
              </span>
              <span className="large">
                Palmbomen
              </span>
            </div>
          )}

          {transLastSlide(({ x }, item) => item && (
            <a.div
              css={styles.slideLast()}
              style={{ opacity: x.to((x) => x) }}
            >
              <a.button
                type="button"
                css={styles.replayButton()}
                onClick={() => {
                  mutation.frame = 0;
                  useStore.setState({ playing: true });
                  setSlide(null);
                }}
              >
                <svg
                  height="100%"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  fill="#bbb"
                >
                  <path d="M64,8A55.43,55.43,0,0,0,27,22L17.47,10.81,12.63,41.67l31.24.23L34.75,31.15A43.53,43.53,0,0,1,64,20,44,44,0,1,1,20,64H8A56,56,0,1,0,64,8Z" />
                </svg>
              </a.button>
              {/* <button
                css={styles.infoButton()}
                type="button"
                onClick={() => { setShowInfo(true); }}
              >
                INFO
              </button> */}
            </a.div>
          ))}
          {/*
          <div
            css={styles.info()}
            className={showInfo ? 'open' : ''}
          >
            <p>
              This is a webVR environment that was
              exhibited as part of EUNSOL LEE's solo exhibition
              《KIMBERLY EXTRACT》
            </p>
          </div> */}

        </a.div>
      ))}
    </>
  );
};

export default Outro;

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved, no-param-reassign */
/**
 * Simple underwater shader
 *

 parameters:
 tDiffuse: texture
 time: this should increase with time passing
 distort_speed: how fast you want the distortion effect of water to proceed
 distortion: to what degree will the shader distort the screen
 centerX: the distortion center X coord
 centerY: the distortion center Y coord

 explaination:
 the shader is quite simple
 it chooses a center and start from there make pixels around it to "swell" then "shrink" then "swell"...
 this is of course nothing really similar to underwater scene
 but you can combine several this shaders together to create the effect you need...
 And yes, this shader could be used for something other than underwater effect, for example, magnifier effect :)

 * @author vergil Wang
 */

import {
  Mesh, OrthographicCamera, PlaneBufferGeometry, Scene, ShaderMaterial, UniformsUtils, Vector2,
} from 'three';
import { Pass } from 'three/examples/jsm/postprocessing/Pass';
import lensVert from '!!raw-loader!../shaders/lens.vert';
import lensFrag from '!!raw-loader!../shaders/lens.frag';

// const LensDistortShader = {
//   uniforms: {
//     tex: { type: 't', value: null },
//     time: { type: 'f', value: 0.0 },
//   },
//   vertexShader: lensVert,
//   fragmentShader: lensFrag,
// };

// const LensDistortPass = function (dtSize) {
//   Pass.call(this);
//   const shader = LensDistortShader;
//   this.uniforms = UniformsUtils.clone(shader.uniforms);
//   this.material = new ShaderMaterial({
//     uniforms: this.uniforms,
//     vertexShader: shader.vertexShader,
//     fragmentShader: shader.fragmentShader,
//   });
//   this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
//   this.scene = new Scene();
//   this.quad = new Mesh(new PlaneBufferGeometry(2, 2), null);
//   this.quad.frustumCulled = false; // Avoid getting clipped
//   this.scene.add(this.quad);
//   this.time = 0;
// };

// LensDistortPass.prototype = Object.assign(Object.create(Pass.prototype), {
//   constructor: LensDistortPass,

//   render(renderer, writeBuffer, readBuffer, deltaTime, maskActive) {
//     this.uniforms.tex.value = readBuffer.texture;
//     this.uniforms.time.value = this.time;
//     this.time += 0.05;
//     this.quad.material = this.material;
//     if (this.renderToScreen) {
//       renderer.setRenderTarget(null);
//       renderer.render(this.scene, this.camera);
//     } else {
//       renderer.setRenderTarget(writeBuffer);
//       if (this.clear) renderer.clear();
//       renderer.render(this.scene, this.camera);
//     }
//   },
// });

const shader = {
  vertexShader: lensVert,
  fragmentShader: lensFrag,
  uniforms: {
    tex: { value: null },
    // scale: { value: 0 },
    // factor: { value: 0 },
    resolution: { value: new Vector2(64, 64) },

  },
};

class LensDistortPass extends Pass {
  constructor(dt_size = 64) {
    super();
    this.uniforms = UniformsUtils.clone(shader.uniforms);
    this.uniforms.resolution.value = new Vector2(dt_size, dt_size);
    this.material = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
    });
    this.camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
    this.scene = new Scene();
    this.quad = new Mesh(new PlaneBufferGeometry(2, 2, 1, 1), null);
    this.quad.frustumCulled = false; // Avoid getting clipped
    this.scene.add(this.quad);
    // this.factor = 0
    this.time = 0;
  }

  render(renderer, writeBuffer, readBuffer, deltaTime, maskActive) {
    // const factor = Math.max(0, this.factor)
    // this.uniforms["byp"].value = factor ? 0 : 1
    this.uniforms.tex.value = readBuffer.texture;
    // this.uniforms["factor"].value = this.factor
    this.quad.material = this.material;
    this.time += 0.05;
    if (this.renderToScreen) {
      renderer.setRenderTarget(null);
      renderer.render(this.scene, this.camera);
    } else {
      renderer.setRenderTarget(writeBuffer);
      if (this.clear) renderer.clear();
      renderer.render(this.scene, this.camera);
    }
  }
}

export default LensDistortPass;
